import React from "react";
import { Color, Variables } from "../designToken";
import { SectionTitle } from "./sectionTitle";
import refcomeLogo from "../../images/refcomeLogo.png";
import refcomeTeamsLogo from "../../images/refcomeTeamsLogo.png";
import { TextLink } from "./textLink";
import { NAV_ITEMS } from "../../constants/navigation";

export const Service: React.FC = () => {
  return (
    <>
      <style jsx>{`
        .section {
          padding: 0;
        }
        .sectionInner {
          position: relative;
          max-width: none;
          padding: 100px 0 0;
        }
        ul {
          display: flex;
          justify-content: center;
          padding: 0 50px;
          margin-top: 50px;
        }
        li {
          width: 50%;
          max-width: 300px;
          margin: 0px 30px;
        }
        a:link dl,
        a:visited dl,
        a:active dl,
        a:hover dl {
          color: ${Color.defaultText};
          font-weight: normal;
        }
        .imgWrapper {
          height: 0;
          width: 100%;
          padding-bottom: 50%;
          border-radius: 10px;
          background-size: auto 40%;
          background-position: center;
          background-repeat: no-repeat;
          background-color: ${Color.subBg};
          border-radius: 10px;
        }
        .imgWrapper.refcome {
          background-image: url(${refcomeLogo});
        }
        .imgWrapper.refcomeTeams {
          background-image: url(${refcomeTeamsLogo});
        }
        dl {
          margin-top: 10px;
        }
        dl dt {
          font-size: 16px;
        }
        dl dd {
          font-size: 15px;
          line-height: 1.4;
        }
        @media (${Variables.breakPoint.sp}) {
          .section {
            padding: 0;
          }
          .sectionInner {
            padding-top: 70px;
          }
          ul {
            display: block;
            padding: 0 30px;
          }
          li {
            width: 100%;
            max-width: 300px;
            margin: 0px auto 40px;
          }
          li:last-child {
            margin-bottom: 0px;
          }
          dl dd {
            font-size: 13px;
          }
        }
      `}</style>
      <section className="section" id="service">
        <div className="sectionInner">
          <SectionTitle text={NAV_ITEMS.SERVICE.NAME} subText={NAV_ITEMS.SERVICE.JP_NAME} />
          <ul>
            <li>
              <div className="imgWrapper refcome" />
              <dl>
                <dt>リフカム</dt>
                <dd>リファラル採用を見える化し、共にカイゼンする伴走型サービス</dd>
                <dd>
                  <TextLink text="Visit" url="https://refcome.com/" blank />
                </dd>
              </dl>
            </li>
            <li>
              <div className="imgWrapper refcomeTeams" />
              <dl>
                <dt>リフカム チームズ</dt>
                <dd>全員参加型リファラル採用を支えるプラットフォーム</dd>
                <dd>
                  <TextLink text="Visit" url="https://refcome.team/" blank className="textLink" />
                </dd>
              </dl>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};
