import React from "react";
import { Link } from "gatsby";
import { Color } from "../designToken";
import RightArrow from "../../images/inline_svg/rightArrow.svg";

interface Props {
  text: string;
  url: string;
  blank?: boolean;
  className?: string;
}
const isInternalLink = (url) => {
  let result = true;
  try {
    /* TODO:必要があればちゃんと判別かく */
    const urlObj = new URL(url);
    if (urlObj.hostname !== "about.refcome.com") {
      result = false;
    }
  } finally {
    return result;
  }
};
export const TextLink: React.FC<Props> = ({ text, url, blank, className }) => {
  const content = (
    <div className="moreLink">
      <span>{text}</span>
      <img src={RightArrow} alt="" />
    </div>
  );
  return (
    <>
      <style jsx>{`
        .textLinkWrapper {
          display: inline-block;
        }
        .textLinkWrapper :global(a) {
          transition: opacity 0.2s ease;
          font-size: 20px;
          font-weight: 500;
          display: inline-block;
        }
        .textLinkWrapper :global(a:hover) {
          opacity: 0.7;
        }
        .textLinkWrapper :global(a span) {
          display: inline-block;
          vertical-align: middle;
          color: ${Color.brand};
          margin-right: 2px;
        }
        .textLinkWrapper :global(a img) {
          position: relative;
          top: 2px;
          display: inline-block;
          vertical-align: middle;
        }
      `}</style>
      <div className={`textLinkWrapper ${className}`}>
        {isInternalLink(url) ? (
          <Link to={url}>{content}</Link>
        ) : (
          <a href={url} target={blank ? "_blank" : "_self"} rel="noopener noreferrer">
            {content}
          </a>
        )}
      </div>
    </>
  );
};
