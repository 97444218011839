import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Color, Variables } from "../designToken";
import { SectionTitle } from "./sectionTitle";
import DateText from "../dateText";
import rightArrow from "../../images/inline_svg/rightArrow.svg";
import { TextLink } from "./textLink";
import { NAV_ITEMS } from "../../constants/navigation";

export const News: React.FC<{}> = () => {
  const { allContentfulArticle } = useStaticQuery<GatsbyTypes.TopPageArticleQuery>(graphql`
    query TopPageArticle {
      allContentfulArticle(limit: 5, sort: { fields: date, order: DESC }) {
        edges {
          node {
            title
            category {
              name
            }
            date
            contentful_id
          }
        }
      }
    }
  `);

  return (
    <>
      <style jsx>{`
        .section {
          position: relative;
          margin: 20px 0 0;
        }
        .sectionInner {
          padding: 100px 0;
          max-width: 900px;
        }
        .listWrapper {
          position: relative;
          margin-top: 40px;
        }
        ul {
          width: 100%;
        }
        ul li {
          padding: 20px 15px;
          transition-property: transform;
          transition-timing-function: ease;
          transition-duration: 0.3s;
          border-bottom: solid 1px rgba(0, 0, 0, 0.05);
        }
        ul li:hover {
          transform: translateY(1px);
        }
        ul li:hover .mainText {
          opacity: 0.7;
        }
        ul .subText {
          width: 130px;
          color: ${Color.lightText};
          display: inline-block;
          vertical-align: middle;
        }
        ul .contentBox {
          display: block;
          width: 100%;
        }
        ul .subText .date {
          font-size: 14px;
          padding-right: 10px;
          letter-spacing: 1px;
        }
        ul .mainText {
          font-size: 15px;
          color: ${Color.defaultText};
          transition-property: opacity;
          transition-timing-function: ease;
          transition-duration: 0.3s;
          position: relative;
          display: inline-block;
          width: calc(100% - 130px);
          vertical-align: middle;
        }
        ul .mainText .text {
          position: relative;
          display: inline-block;
          width: calc(100% - 29px);
          margin-right: 5px;
          overflow: hidden;
          max-height: calc(18px * 1.8 * 2);
          vertical-align: middle;
        }
        ul .mainText img {
          display: inline-block;
          vertical-align: middle;
        }
        ul .mainText .text:before,
        ul .mainText .text:after {
          position: absolute;
          background: #fff;
        }
        ul .mainText .text:before {
          content: "...";
          top: calc(18px * 1.8);
          right: 0;
          display: inline-block;
          padding-left: 5px;
          color: ${Color.lightText};
        }
        ul .mainText .text:after {
          content: "";
          width: 100%;
          height: 100%;
        }
        .section :global(.textLink) {
          position: absolute;
          height: 25px;
          right: 0px;
          bottom: -40px;
          margin: auto;
        }
        @media (${Variables.breakPoint.sp}) {
          .section {
            margin: 100px 0 0;
          }
          .sectionInner {
            padding: 0 0 50px;
          }
          .listWrapper {
            margin-top: 15px;
          }
          ul {
            width: 100%;
          }
          ul li {
            padding: 20px 0;
          }
          ul li:nth-child(n + 4) {
            display: none;
          }
          ul .subText {
            display: block;
          }
          ul .subText .date {
            font-size: 13px;
          }
          ul .mainText {
            font-size: 13px;
            margin-top: 5px;
            padding-left: 0;
            display: block;
            width: 100%;
          }
          ul .mainText .text {
            max-height: calc(13px * 1.6 * 2);
            line-height: 1.6;
          }
          ul .mainText .text:before {
            top: calc(15px * 1.6);
          }
        }
      `}</style>
      <section className="section" id="news">
        <div className="sectionInner">
          <SectionTitle text={NAV_ITEMS.NEWS.NAME} subText={NAV_ITEMS.NEWS.JP_NAME} />
          <div className="listWrapper">
            <ul>
              {allContentfulArticle.edges.map((edge) => (
                <li key={edge.node.contentful_id}>
                  <a href={`/news/${edge.node.contentful_id}`}>
                    <div className="category">{edge.node.category.abbreviation}</div>
                    <div className="contentBox">
                      <div className="subText">
                        <DateText dateTime={edge.node.date} />
                      </div>
                      <div className="mainText">
                        <div className="text">{edge.node.title}</div>
                        <img src={rightArrow} alt="" />
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
            <TextLink text="More" url="/news" className="textLink" />
          </div>
        </div>
      </section>
    </>
  );
};
