import React from "react";
import { Link } from "gatsby";
import { Variables, Color } from "~/components/designToken";
import { Button } from "~/components/button";
import { SectionTitle } from "~/components/top/sectionTitle";
import { NAV_ITEMS } from "~/constants/navigation";
import { Members } from "~/components/Members";
import RightArrow from "~/images/inline_svg/rightArrow.svg";

type Props = {
  members: GatsbyTypes.ContentfulMemberConnection;
};

export const Member: React.FC<Props> = ({ members }) => {
  return (
    <>
      <style jsx>{`
        .wrapper {
          margin: 100px auto 0;
          width: 100%;
          padding: 0 30px;
        }
        .container {
          max-width: 800px;
          margin: 0 auto;
          padding: 0;
        }
        .memberWrapper {
          display: flex;
          flex-wrap: wrap;
        }
        .btnWrapper {
          display: flex;
          justify-content: center;
          padding: 0 20px;
        }
        .btnWrapper :global(.btn) {
          display: block;
        }
        .button {
          border: none;
          cursor: pointer;
          outline: none;
          display: block;
        }
        .viewMore {
          display: flex;
          justify-content: center;
        }
        .viewMore :global(a) {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-weight: 700;
          line-height: 1;
          padding: 10px 15px;
          color: ${Color.brand};
        }
        .viewMore :global(span) {
          display: block;
        }
        .viewMore :global(img) {
          display: block;
          height: 24px;
          width: auto;
          margin-top: 2px;
        }
        @media (${Variables.breakPoint.maxSp}) {
          .btnWrapper {
            margin: 30px auto 0;
          }
        }
        @media (${Variables.breakPoint.minPc}) {
          .btnWrapper {
            margin: 50px auto 0;
          }
        }
      `}</style>
      <section id="member" className="wrapper">
        <div className="container">
          <SectionTitle text={NAV_ITEMS.MEMBER.NAME} subText={NAV_ITEMS.MEMBER.JP_NAME} />
          <Members members={members} />
          <div className="viewMore">
            <Link to="/member">
              <span>More</span>
              <img src={RightArrow} />
            </Link>
          </div>
          {/*<div className="btnWrapper">*/}
          {/*  <Button href="https://refcome.refcome.team/public_flyers/01866f95-c912-8437-061b-3719e8e6dcf7" mainText="Open Positions" smallText="採用情報" />*/}
          {/*</div>*/}
        </div>
      </section>
    </>
  );
};
