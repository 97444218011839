import React from "react";

interface Props {
  dateTime: string;
}

const DateText: React.FC<Props> = ({ dateTime }) => {
  const date = dateTime.split("T")[0];

  return (
    <>
      <span className="date">{date.replace(/-/g, ".")}</span>
    </>
  );
};

export default DateText;
