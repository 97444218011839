import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Color, Variables } from "../designToken";
import makeATeamSvg from "../../images/makeATeam.svg";

// make a teamアニメーションの全体尺
const TEXT_DISSOLVED_DURATION = 20;
// make a teamアニメーションの一単語単位の尺
const DELAY_DURATION = TEXT_DISSOLVED_DURATION / 5;
// firstViewのmax width
const FIRST_VIEW_MAX_WIDTH = 1120;

export const FirstView: React.FC = () => {
  const [exceededFirstViewMaxWidth, setExceededFirstViewMaxWidth] = React.useState<boolean>(false);
  const firstViewWrapper = React.useRef(null);

  const isExceededFirstViewMaxWidth = () => firstViewWrapper.current.clientWidth >= FIRST_VIEW_MAX_WIDTH;
  React.useEffect(() => {
    setExceededFirstViewMaxWidth(isExceededFirstViewMaxWidth());
    window.addEventListener("resize", () => {
      setExceededFirstViewMaxWidth(isExceededFirstViewMaxWidth());
    });
  }, []);

  const { logoAndText, firstViewPc, firstViewSmp } = useStaticQuery(graphql`
    query {
      logoAndText: file(relativePath: { eq: "logoAndText.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 140, placeholder: NONE)
        }
      }
      firstViewPc: file(relativePath: { eq: "firstViewPc.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 1500, placeholder: NONE)
        }
      }
      firstViewSmp: file(relativePath: { eq: "firstViewSmp.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 766, placeholder: NONE)
        }
      }
    }
  `);

  return (
    <>
      <style jsx>{`
        .section {
          padding: 0;
          overflow: hidden;
        }
        .sectionInner {
          width: 100%;
          margin: 0;
          max-width: none;
          padding: 0;
        }
        .firstViewWrapper {
          position: relative;
          width: 100%;
          max-width: ${FIRST_VIEW_MAX_WIDTH}px;
          margin: 0 auto;
          padding: 20px;
        }
        .firstViewWrapper.spVisible {
          padding: 20px 0;
        }
        .logoWrapper {
          position: absolute;
          width: 20%;
          max-width: 58px;
          top: 40px;
          left: 40px;
          z-index: 1;
        }
        .firstViewImgWrapper.pcVisible {
          display: block;
          position: relative;
          z-index: 0;
          margin-top: 50px;
        }
        .firstViewImgWrapper.spVisible {
          display: none;
        }
        .makeATeamWrapper {
          position: absolute;
          bottom: -10%;
          left: 5%;
          margin: auto;
          width: 20%;
          z-index: 1;
        }
        .makeATeamWrapper img {
          width: 100%;
        }
        .makeATeamWrapper .subText {
          position: absolute;
          width: 110%;
          height: 30%;
          border-bottom: solid 3px ${Color.brand};
          top: calc(30% - 4px);
          left: 28%;
        }
        .makeATeamWrapper .subText span {
          content: "";
          position: absolute;
          width: 100%;
          font-size: ${exceededFirstViewMaxWidth ? `90px` : `calc(26px + 5vw)`};
          line-height: 2;
          text-align: left;
          color: ${Color.brand};
          top: ${exceededFirstViewMaxWidth ? `-40px` : `calc(-25px - 1vw)`};
          font-family: "Buttervill", sans-serif;
        }
        .makeATeamWrapper .subText span:before {
          content: "";
          display: inline-block;
          vertical-align: middle;
          width: 25px;
        }
        .makeATeamWrapper .subText span:nth-child(1) {
          animation: textDissolve ${`${TEXT_DISSOLVED_DURATION}s`} ${`${DELAY_DURATION * 0}s`} ease infinite both;
        }
        .makeATeamWrapper .subText span:nth-child(2) {
          animation: textDissolve ${`${TEXT_DISSOLVED_DURATION}s`} ${`${DELAY_DURATION * 1}s`} ease infinite both;
        }
        .makeATeamWrapper .subText span:nth-child(3) {
          animation: textDissolve ${`${TEXT_DISSOLVED_DURATION}s`} ${`${DELAY_DURATION * 2}s`} ease infinite both;
        }
        .makeATeamWrapper .subText span:nth-child(4) {
          animation: textDissolve ${`${TEXT_DISSOLVED_DURATION}s`} ${`${DELAY_DURATION * 3}s`} ease infinite both;
        }
        .makeATeamWrapper .subText span:nth-child(5) {
          animation: textDissolve ${`${TEXT_DISSOLVED_DURATION}s`} ${`${DELAY_DURATION * 4}s`} ease infinite both;
        }
        @keyframes textDissolve {
          0% {
            opacity: 0;
          }
          1% {
            opacity: 0;
          }
          8% {
            opacity: 1;
          }
          12% {
            opacity: 1;
          }
          19% {
            opacity: 0;
          }
          100% {
            opacity: 0;
          }
        }
        .mainContent {
          display: block;
          width: 100%;
          padding-top: ${exceededFirstViewMaxWidth ? `108px` : `10%`};
          text-align: center;
        }
        .catch {
          display: block;
          font-size: 63px;
          font-weight: 500;
          margin: 0 0 15px;
        }
        p {
          font-size: 18px;
          font-weight: 500;
          line-height: 2.6;
          margin: 30px auto 0 0;
          color: ${Color.defaultText};
        }
        @media (${Variables.breakPoint.sp}) {
          .sectionInner {
            padding: 0;
          }
          .logoWrapper {
            display: block;
            top: 40px;
            left: 30px;
            margin: 0;
            width: 34px;
          }
          .makeATeamWrapper {
            bottom: auto;
            width: 35%;
            top: calc(10% + 80px);
            left: 30px;
          }
          .makeATeamWrapper .subText span {
            font-size: 15vw;
            top: calc(-10px - 6vw);
          }
          .makeATeamWrapper .subText span:before {
            width: 15px;
          }
          .firstViewImgWrapper.pcVisible {
            display: none;
          }
          .firstViewImgWrapper.spVisible {
            display: block;
            margin-top: 50%;
          }
          .mainContent {
            padding: 0;
            width: calc(100% - 30px);
            margin: 0 15px;
            display: block;
          }
          p {
            width: 100%;
            padding: 0 15px;
            line-height: 2.2;
            margin: 0 auto;
            font-size: 13px;
          }
          .catch {
            font-size: 30px;
            margin-top: 20px;
          }
        }
      `}</style>
      <section className="section">
        <div className="sectionInner">
          <div className="firstViewWrapper spVisible" ref={firstViewWrapper}>
            <div className="logoWrapper">
              <GatsbyImage image={logoAndText.childImageSharp.gatsbyImageData} alt="" />
            </div>
            <div className="makeATeamWrapper">
              <img src={makeATeamSvg} alt="make a team" />
              <div className="subText">
                <span>fun</span>
                <span>great</span>
                <span>creative</span>
                <span>humane</span>
                <span>decisive</span>
              </div>
            </div>
            <div className="firstViewImgWrapper pcVisible">
              <GatsbyImage image={firstViewPc.childImageSharp.gatsbyImageData} alt="" />
            </div>
            <div className="firstViewImgWrapper spVisible">
              <GatsbyImage image={firstViewSmp.childImageSharp.gatsbyImageData} alt="" />
            </div>
          </div>
          <div className="mainContent">
            <h2 className="catch">採用を、仲間集めに。</h2>
            <p>
              <span>生きがいを持って働く人と、魅力的に働く</span>
              <span>会社を世の中に増やしたい。</span>
              <br />
              これが私たちの信条です。
              <br />
              <span>「愛着溢れる会社をつくり、社員全員で、</span>
              <span>一緒に働きたい人を集めていく。」</span>
              <br />
              そんな採用のあり方を、私たちは提唱します。
              <br />
              <span>「実態よりも良く見せて、人事担当者だけで</span>
              <span>採用人数を追い求めていく」</span>
              <br />
              そんな採用は、もう終わりにしましょう。
              <br />
              採用を、「仲間集め」に変革する。
              <br />
              それを果たすことができたとき、
              <br />
              <span>働く人も、会社も、おたがいにとって</span>
              <span>幸せな世の中となるはず。</span>
              <br />
              私たちは、一日でも早く、この挑戦を成し遂げます。
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
