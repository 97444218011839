import React from "react";
import GoogleMapReact, { Maps } from "google-map-react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Variables } from "../designToken";
import { SectionTitle } from "./sectionTitle";
import { Button } from "../button";
import { NAV_ITEMS } from "../../constants/navigation";
import { Color } from "../designToken";

const GOOGLE_MAP_KEY = `AIzaSyBSI3IDj8s5nfxNS6X83hQqV3xWaquysw4`;

const Map: React.FC<{}> = () => {
  const { mapPinPng } = useStaticQuery<GatsbyTypes.mapImgsQuery>(graphql`
    query mapImgs {
      mapPinPng: file(relativePath: { eq: "mapPin.png" }) {
        childImageSharp {
          fixed(width: 20) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const createMapOptions = (maps: Maps) => {
    return {
      styles: [
        {
          featureType: "administrative",
          elementType: "all",
          stylers: [
            {
              saturation: -100,
            },
          ],
        },
        {
          featureType: "administrative.country",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "administrative.country",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "administrative.province",
          elementType: "all",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [
            {
              saturation: -100,
            },
            {
              lightness: 65,
            },
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: [
            {
              saturation: -100,
            },
            {
              lightness: 50,
            },
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi.attraction",
          elementType: "geometry.stroke",
          stylers: [
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "all",
          stylers: [
            {
              saturation: -100,
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.highway.controlled_access",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "all",
          stylers: [
            {
              lightness: 30,
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry",
          stylers: [
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "all",
          stylers: [
            {
              lightness: 40,
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "labels.text.fill",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "labels.text.stroke",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "all",
          stylers: [
            {
              saturation: -100,
            },
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              hue: "#ffff00",
            },
            {
              lightness: -25,
            },
            {
              saturation: -97,
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels",
          stylers: [
            {
              lightness: -25,
            },
            {
              saturation: -100,
            },
          ],
        },
      ],
    };
  };

  const defaultPosition = {
    center: {
      lat: 35.671855,
      lng: 139.70851,
    },
    zoom: 17,
    pin: {
      lat: 35.671806,
      lng: 139.708714,
    },
  };

  return (
    <div className="map">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: GOOGLE_MAP_KEY,
        }}
        defaultCenter={{
          lat: defaultPosition.center.lat,
          lng: defaultPosition.center.lng,
        }}
        defaultZoom={defaultPosition.zoom}
        options={createMapOptions}
      >
        <Img {...defaultPosition.pin} fixed={mapPinPng.childImageSharp.fixed} />
      </GoogleMapReact>
    </div>
  );
};

export const Company: React.FC<{}> = () => {
  return (
    <>
      <style jsx>{`
        a {
          color: ${Color.defaultText};
        }
        a:hover {
          opacity: 0.7;
        }
        .sectionInner {
          max-width: 900px;
          padding: 100px 0 0;
        }
        .flexWapper {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-top: 70px;
        }
        .descriptionWrapper {
          width: calc(65% - 60px);
          margin-right: 60px;
        }
        .flexBlock {
          border-bottom: solid 1px rgba(0, 0, 0, 0.05);
          display: flex;
          padding: 15px 0;
          font-size: 14px;
        }
        .flexBlock:last-child {
          border-bottom: none;
        }
        .flexBlock h3 {
          width: 40%;
          font-weight: 500;
          font-size: 14px;
          position: relative;
          margin-right: 15px;
        }
        .flexBlock h3 span {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
        .flexBlock p {
          width: 60%;
          letter-spacing: 0.08rem;
          font-size: 13px;
        }
        .licenseNumber {
          width: 60%;
          letter-spacing: 0.08rem;
          font-size: 13px;
        }
        .licenseNumber .head {
          margin-right: 5px;
        }
        .licenseNumber .hyphen {
          font-size: 1.16em;
          margin: 0 3px;
        }
        .licenseNumber .en {
          font-size: 1.16em;
        }
        .flexBlock p span {
          font-size: ${13 * 1.16}px;
        }
        .flexBlock p .info {
          font-size: 12px;
        }
        #company :global(.map) {
          border: 0;
          width: 30%;
          height: 300px;
        }
        .buttonWrapper {
          text-align: center;
          margin: 80px 0 0;
        }
        .section :global(.btn) {
          margin: 0;
        }
        @media (${Variables.breakPoint.sp}) {
          .section {
            padding: 0;
          }
          .sectionInner {
            padding: 70px 0 20px;
          }
          .flexWapper {
            display: block;
            margin-top: 40px;
          }
          .descriptionWrapper {
            width: 100%;
            margin-right: 60px;
            margin-bottom: 30px;
            padding: 0 20px;
          }
          .flexBlock p .info.pcVisible {
            display: none;
          }
          #company :global(.map) {
            width: 100%;
            height: calc(200px + 3vw);
          }
          .info {
            display: block;
            font-size: 12px;
            margin-top: 15px;
          }
          .flexBlock h3 {
            font-size: 14px;
          }
          .buttonWrapper {
            margin: 50px 0 0;
          }
        }
      `}</style>
      <section className="section" id="company">
        <div className="sectionInner">
          <SectionTitle text={NAV_ITEMS.COMPANY.NAME} subText={NAV_ITEMS.COMPANY.JP_NAME} />
          <div className="flexWapper">
            <div className="descriptionWrapper">
              <div className="flexBlock">
                <h3>
                  <span>社名</span>
                </h3>
                <p>株式会社リフカム</p>
              </div>
              <div className="flexBlock">
                <h3>
                  <span>経営陣</span>
                </h3>
                <p>
                  代表取締役<span>CEO</span> 清水 巧
                </p>
              </div>
              <div className="flexBlock">
                <h3>
                  <span>事業内容</span>
                </h3>
                <p>
                  リファラル採用を活性化するサービス
                  <br />「<span>Refcome</span>」「<span>Refcome Teams</span>」の開発・運営等
                </p>
              </div>
              <div className="flexBlock">
                <h3>
                  <span>設立</span>
                </h3>
                <p>
                  <span>2014</span>年<span>1</span>月<span>24</span>日
                </p>
              </div>
              <div className="flexBlock">
                <h3>
                  <span>資本金</span>
                </h3>
                <p>
                  <span>412</span>百万円（資本準備金含む）
                </p>
              </div>
              {/*<div className="flexBlock">*/}
              {/*  <h3>*/}
              {/*    <span>有料職業紹介事業</span>*/}
              {/*  </h3>*/}
              {/*  <div className="licenseNumber">*/}
              {/*    <span className="head">許可番号</span>*/}
              {/*    <span className="en">13</span>*/}
              {/*    <span className="hyphen">-</span>ユ<span className="hyphen">-</span>*/}
              {/*    <span className="en">312962</span>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="flexBlock">
                <h3>
                  <span>プライバシーマーク認証</span>
                </h3>
                <p>
                  認定 <span>2018</span>年<span>4</span>月<span>13</span>日
                  <br />
                  登録番号 第<span>21004461(01)</span>号
                </p>
              </div>
              <div className="flexBlock">
                <h3>
                  <span>所在地</span>
                </h3>
                <p>
                  〒<span>150-0001</span>
                  <br />
                  <a href="https://goo.gl/maps/DrUQTfDDGAN5FzqJ7" target="_blank">
                    東京都渋谷区神宮前<span>3-27-15 FLAG 3L</span>
                  </a>
                  <br />
                  <span className="info">
                    東京メトロ千代田線・副都心線 明治神宮前駅より徒歩<span>5</span>分
                  </span>
                </p>
              </div>
            </div>
            <Map />
          </div>
          <div className="buttonWrapper">
            <Button href="/contact" mainText="Contact" />
          </div>
        </div>
      </section>
    </>
  );
};
