import React from "react";
import { graphql } from "gatsby";
import { Layout } from "../components/layout";
import { FirstView } from "../components/top/firstView";
import { Service } from "../components/top/service";
import { Member } from "../components/top/Member";
import { News } from "../components/top/news";
import { Company } from "../components/top/company";

type Props = {
  data: {
    members: GatsbyTypes.ContentfulMemberConnection;
  };
};

const IndexPage: React.FC<Props> = ({ data }) => {
  return (
    <Layout hasStickyHeader>
      <FirstView />
      <Service />
      <Member members={data.members} />
      <News />
      <Company />
    </Layout>
  );
};

export default IndexPage;

export const IndexPageQuery = graphql`
  query IndexPage {
    members: allContentfulMember(sort: { fields: order, order: ASC }, limit: 8) {
      totalCount
      edges {
        node {
          id
          contentful_id
          name
          nameJa
          order
          position
          profile {
            id
            profile
          }
          twitter
          facebook
          avatar {
            id
            contentful_id
            fluid(maxWidth: 1200) {
              src
              aspectRatio
            }
            fixed(cropFocus: BOTTOM, height: 1000, quality: 100) {
              src
              aspectRatio
            }
          }
        }
      }
    }
  }
`;
